// Import your CSS/SCSS files here:

body {
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  @media (max-width: 600px) {
    font-size: 16px;
  }
}
.headroom--not-top {
  background-color: #263372;
  transition-property: all !important;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
  transition-duration: 150ms !important;
  transition-duration: 200ms !important;
  top: 0 !important;
}
#main-header {
  padding: 20px 0;
  transition-property: all !important;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
  transition-duration: 150ms !important;
  transition-duration: 200ms !important;
}
.monserrat {
  font-family: "Montserrat", sans-serif;
}
h1,
.h1 {
  font-size: responsive 50px 90px;
  font-weight: 300;
  line-height: responsive 60px 100px;
  @apply text-text;
}
h2,
.h2 {
  font-size: responsive 22px 25px;
  font-weight: 300;
  line-height: responsive 26px 30px;
  @apply text-text;
}
.wrapper {
  max-width: 1500px;
  margin: auto;
  padding: 0 100px;
  @media (max-width: 800px) {
    padding: 0 40px;
  }
  @media (max-width: 600px) {
    padding: 0 20px;
  }
}
p {
  margin-bottom: 1rem;
}
.quote {
  p {
    font-size: 21px;
  }
}
.overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 1;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(13, 13, 13, 0.5) 100%
  );
}
.home-nav {
  top: 0rem;
  @media (min-width: 768px) {
    top: 2.5rem;
  }
  @media (min-width: 1024px) {
    top: 2.5rem;
  }
}
.sub-nav {
  background-color: #263372;
  padding: 20px 0;
}
.mob-links {
  a {
    font-size: 30px;
    padding: 10px 0;
    margin: 10px 0;
    display: inline-block;
    width: 100%;
  }
}
.ul-list {
  ul {
    li {
      border-bottom: 1px solid #dfdfdf;
      position: relative;
      padding-left: 40px;
      padding-top: 20px;
      padding-bottom: 20px;
      &:before {
        content: "";
        width: 20px;
        height: 20px;
        background-image: url("/assets/tick.svg");
        position: absolute;
        left: 0;
        top: 19px;
      }
    }
  }
}
.mob-nav-btn {
  position: absolute;
  right: 30px;
  top: 20px;
}
.foot-link {
  a {
    border-bottom: 1px solid white;
  }
}
